<template>
  <div class="container-fluid pt-3 px-md-5 px-sm-3" v-if="getAsadQuranResult">
    <div class="d-flex justify-content-center align-items-center">
      <img src="@/assets/al-quran.png" />
      <div><h1 class="al-quran ps-3">Al Quran</h1></div>
    </div>
    <figure class="text-center py-2">
      <blockquote class="blockquote">
        <p>
          The Prophet (ﷺ) said, "The best among you (Muslims) are those who
          learn the Qur'an and teach it."
        </p>
      </blockquote>
      <figcaption class="blockquote-footer">
        Sahih Al Bukhari 1: Chapter 67, Hadith 5027
      </figcaption>
    </figure>
    <div class="row g-4">
      <div
        class="col-sm-4"
        v-for="(result, index) in getAsadQuranResult.data.surahs"
        :key="result"
      >
        <div class="card">
          <div class="card-header card-header-custom">
            <div class="d-flex justify-content-between">
              <div class="lead">
                {{ `${result.englishName} ( ${result.name} )` }}
              </div>
              <div>
                <span class="surah-no rounded-circle p-1">{{
                  result.number
                }}</span>
              </div>
            </div>
          </div>
          <div class="card-body">
            <table class="table">
              <tbody>
                <tr>
                  <td class="col-5">English Meaning:</td>
                  <td>{{ result.englishNameTranslation }}</td>
                </tr>
                <tr>
                  <td class="col-5">Revelation Type:</td>
                  <td>{{ result.revelationType }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card-footer">
            <div class="d-flex justify-content-between">
              <!-- <div>Surah Number: {{ result.number }}</div> -->
              <a class="btn remove-btn" @click="removeSurah(index)"
                ><i class="fa-solid fa-trash pe-2"></i>Remove</a
              >
              <div>
                <router-link
                  :to="'/surah/' + result.number"
                  class="btn read-more-btn"
                  ><i class="fa-solid fa-eye pe-2"></i>Read Full
                  Surah</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "FullQuranAsad",
  data() {
    return {
      getAsadQuranURL: "http://api.alquran.cloud/v1/quran/en.asad",
      getAsadQuranResult: null,
    };
  },
  methods: {
    getAsadQuran() {
      axios
        .get(this.getAsadQuranURL)
        .then((response) => (this.getAsadQuranResult = response.data))
        .catch((error) => {
          console.error(error);
        });
    },
    removeSurah: function (index) {
      this.getAsadQuranResult.data.surahs.splice(index, 1);
    },
  },
  mounted() {
    this.getAsadQuran();
    console.log(this.getAsadQuran());
  },
};
</script>

<style scoped>
.card-header-custom {
  background-color: #6a3ea1;
  color: #ffffff;
}
.read-more-btn {
  background-color: #f8f9fa;
  border: 1px solid #6a3ea1;
  color: #6a3ea1;
  padding: 6px 10px;
  text-decoration: none;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
.read-more-btn:hover {
  background-color: #6a3ea1;
  border: 1px solid #f8f9fa;
  color: #f8f9fa;
}
.remove-btn {
  background-color: #f8f9fa;
  border: 1px solid #6a3ea1;
  color: #6a3ea1;
  padding: 6px 10px;
  text-decoration: none;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
.remove-btn:hover {
  background-color: #dc3545;
  border: 1px solid #dc3545;
  color: #ffffff;
}
.al-quran {
  color: #6a3ea1;
  font-size: 36px;
  font-weight: 800;
  line-height: 54px;
}
.surah-no {
  width: 35px;
  height: 35px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6a3ea1;
  font-size: 18px;
}
</style>