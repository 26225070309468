<template>
  <div class="container pt-4" v-if="surahResult">
    <RouterLink to="/" class="back-to-home-btn btn mb-2"
      >Back to Home</RouterLink
    >
    <div class="card">
      <div
        class="card-header card-header-custom d-flex justify-content-between align-items-center"
      >
        <div class="d-flex align-items-center">
          <div>
            <span class="surah-no rounded-circle">{{
              surahResult.data[0].number
            }}</span>
          </div>
          <div class="ps-2">
            <span
              >{{ surahResult.data[0].englishName }}

              <span class="ps-1">(</span>{{ surahResult.data[0].name
              }}<span>)</span></span
            >
          </div>
        </div>
        <!-- <div>
          <span>Rvelation Type:</span>
          <span>{{ surahResult.data[0].revelationType }}</span>
        </div> -->
        <div>
          <span>Ayah:</span>
          <span class="ps-2">{{ surahResult.data[0].numberOfAyahs }}</span>
        </div>
      </div>
    </div>
    <!--Ayah start-->
    <div class="ayahWrapper mt-5 p-3">
      <div v-for="(result, index) in surahResult.data[0].ayahs" :key="result">
        <div class="my-1 py-2 ps-3 pe-2 single-ayah">
          <div class="pe-3">
            <div class="d-flex align-items-end justify-content-end">
              <div>
                <span class="ayah-number-in-surah rounded-circle me-3">{{
                  surahResult.data[0].ayahs[index].numberInSurah
                }}</span>
              </div>
              <div>
                <span class="ayah-number-in-surah-arabic text-end">
                  {{ surahResult.data[0].ayahs[index].text }}</span
                >
              </div>
            </div>
            <p class="text-start ayah-number-in-surah-meaning">
              {{ surahResult.data[1].ayahs[index].text }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!--Ayah End-->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "SurahQuran",
  data() {
    return {
      surahResult: null,
    };
  },
  methods: {
    getSurahByID: function () {
      const surahNumber = this.$route.params.number;
      //   const surahURL = `http://api.alquran.cloud/v1/surah/${surahNumber}/en.asad`;
      //   const surahURL = `http://api.alquran.cloud/v1/surah/${surahNumber}`;
      const surahURL = `http://api.alquran.cloud/v1/surah/${surahNumber}/editions/quran-uthmani,en.asad,en.pickthall`;
      axios
        .get(surahURL)
        .then((response) => (this.surahResult = response.data))
        .catch((error) => {
          console.error(error);
        });
    },
  },
  mounted() {
    this.getSurahByID();
  },
};
</script>

<style scoped>
.ayahWrapper {
  height: 80vh;
  overflow-y: scroll;
}
.single-ayah {
  /* border: 1px solid #6a3ea1; */
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.card-header-custom {
  background-color: #6a3ea1;
  color: #ffffff;
}
.surah-no {
  width: 35px;
  height: 35px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #6a3ea1;
  font-size: 18px;
}
.ayah-marker {
  background-color: #6a3ea1;
  color: #ffffff;
}
.ayah-number-in-surah {
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  border: 1px solid #000000;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #000000;
  font-size: 20px;
  font-weight: 500;
}
.ayah-number-in-surah-meaning {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
}
.ayah-number-in-surah-arabic {
  font-size: 40px;
  line-height: 60px;
  font-weight: 500;
}
.back-to-home-btn {
  background-color: #6a3ea1;
  border: 1px solid #6a3ea1;
  color: #ffffff;
  padding: 6px 10px;
  text-decoration: none;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

@media only screen and (max-width: 600px) {
  .ayah-number-in-surah-meaning {
    font-size: 14px;
    line-height: 21px;
  }
  .ayah-number-in-surah-arabic {
    font-size: 30px;
    line-height: 45px;
  }
}
</style>