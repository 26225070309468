<template>
  <!-- <HeaderView /> -->
  <RouterView></RouterView>
  <footer class="container-fluid text-center my-3">
    Made with
    <a class="text-decoration-none" href="https://vuejs.org/" target="_blank">
      Vue.js
    </a>
    and ❤️ by
    <a
      class="text-decoration-none"
      href="https://monayemislam.me"
      target="_blank"
    >
      Monayem Islam Tamal
    </a>
  </footer>
</template>

<script>
// import HeaderView from "@/views/global/HeaderView.vue";
// import FooterView from "@/views/global/FooterView.vue";

export default {
  name: "App",
  components: {},
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
#app {
  font-family: "Montserrat", sans-serif;
}
a:hover {
  cursor: pointer;
}
</style>
